<template>
  <input :value="modelValue" @input="updateInput" type="text" class="main-input" :placeholder="pl"  id="phone">
</template>

<script>
export default {
 name: 'main-input',
 props: {
    pl: String,
    modelValue: [String, Number]
 },
 methods: {
  updateInput(event) {
    this.emit('update:modelValue', event.target.value)
  }
 }
}
</script>

<style scoped>
  .main-input {
    padding: 0 0 0 15px;
    width: 285px;
    height: 60px;
    border-radius: 2px;
    background: #ECF1F8;
    color: #322929;
    border: none;
  }

</style>
