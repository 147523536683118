import MainButton from '@/components/UI/MainButton.vue'
import MainInput from '@/components/UI/MainInput.vue'
import MyDialog from '@/components/UI/MyDialog.vue'
import SiteMenu from '@/components/UI/SiteMenu.vue'
import MobileMenu from '@/components/UI/MobileMenu.vue'
import SliderPhoto from '@/components/SliderComponents/SliderPhoto.vue'
import MainForm from '@/components/MainForm.vue'
import FooterSite from '@/components/FooterSite.vue'
import HeaderSite from '@/components/HeaderSite.vue'
import MainForm2 from '@/components/UI/MainForm2.vue'





export default [
  MainButton,
  MainInput,
  MyDialog,
  SiteMenu,
  MobileMenu,
  SliderPhoto,
  MainForm,
  FooterSite,
  HeaderSite,
  MainForm2
]
