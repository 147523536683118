<template>
    <div>
        <HeaderSite></HeaderSite>
    </div>

  <section class="portfolioComponentSection">
    <div class="options">
        <h1 class="name_portfolioItem">{{ portfolioOption.name_portfolioItem }}</h1>
        <div class="optionList">
          <div class="location optionItem">
            <img src="@/assets/icons/portfolioIcons/location.svg" alt="" class="optionIcon">
            <p class="location_p optionP">{{ portfolioOption.location_p }}</p>
          </div>
          <div class="footage optionItem">
            <img src="@/assets/icons/portfolioIcons/footage.svg" alt="" class="optionIcon">
            <p class="footage_p optionP">{{ portfolioOption.footage_p }}</p>
          </div>
          <div class="cost optionItem">
            <img src="@/assets/icons/portfolioIcons/cost.svg" alt="" class="optionIcon">
            <p class="cost_p optionP">{{ portfolioOption.cost_p }}</p>
          </div>
          <div class="period optionItem">
            <img src="@/assets/icons/portfolioIcons/period.svg" alt="" class="optionIcon">
            <p class="period_p optionP">{{ portfolioOption.period_p }}</p>
          </div>
        </div>
        <h1 class="h1_completedWorks">Виконані роботи:</h1>
        <p class="completedWorks_p">{{ portfolioOption.completedWorks_p }}</p>
    </div>

    <div class="portfolioImages">
        <div v-for="(img, index) in portfolioImages" :key="index" @click="openFullScreen(index)">
            <img :src="getImgUrl(img.srcImg)" alt="">
        </div>
    </div>

    <div v-if="isFullScreen" class="fullScreenOverlay" @click="closeFullScreen">
      <div class="fullScreenImage">
        <img :src="getImgUrl(fullScreenImage.srcImg)" alt="">
        <button class="closeButton" @click="closeFullScreen">Close</button>
      </div>
    </div>

    <div class="dividedLine">
        <h1>Інші приклади робіт</h1>
    </div>

    <div class="anotherItems">
        <prototipePortfolio :slides="otherItems"></prototipePortfolio>
    </div>
  </section>

  <FooterSite></FooterSite>
</template>

<script>
import prototipePortfolio from '@/components/completedWorks/prototipeBlockPortfolio.vue'


export default {
    name: 'faynaTownComponent',
    components: {
        prototipePortfolio
    },
    data() {
      return {
        isFullScreen: false,
        fullScreenIndex: null,
        portfolioImages: [
                    {"id":1, "srcImg": "sliders/fainaTown/01.webp"},
                    {"id":2, "srcImg": "sliders/fainaTown/02.webp"},
                    {"id":3, "srcImg": "sliders/fainaTown/03.webp"},
                    {"id":4, "srcImg": "sliders/fainaTown/04.webp"},
                    {"id":5, "srcImg": "sliders/fainaTown/05.webp"},
                    {"id":6, "srcImg": "sliders/fainaTown/06.webp"},
                    {"id":7, "srcImg": "sliders/fainaTown/07.webp"},
                    {"id":8, "srcImg": "sliders/fainaTown/08.webp"},
                ],
        portfolioOption: {
            "name_portfolioItem" : "Ремонт квартири в ЖК Файна Таун, Новобудова",
            "location_p": "ЖК Файна Таун",
            "footage_p": "63 кв. м.",
            "cost_p": "720 тис. грн.",
            "period_p": "5 міс.",
            "completedWorks_p": "Ремонт квартири 63 м2, 720000 грн, термін виконання 5 місяців, квартира за адресою ЖК Файна таун.", 
        },

        otherItems: [
  
        {
          "id": 1.1,
          "photos": [
            {"id":1, "srcImg": "sliders/zapovitniy/01.webp"},
            {"id":2, "srcImg": "sliders/zapovitniy/02.webp"},
            {"id":3, "srcImg": "sliders/zapovitniy/03.webp"},
            {"id":4, "srcImg": "sliders/zapovitniy/04.webp"},
            ],
          
          "name_portfolioItem" : " Ремонт на пер. Заповітний, приватний будинок",
          "location_p": "Київ, Соломенка",
          "footage_p": "215 кв. м.",
          "cost_p": "146 тис. грн.",
          "period_p": "3 тижні",
          "completedWorks_p": "Виконання фасадних робіт",
          "portfolioLink": "/zapovitniy"
        },
  
            {
          "id": 1.2,
          "photos": [
            {"id":1, "srcImg": "sliders/jkYaskraviy/01.webp"},
            {"id":2, "srcImg": "sliders/jkYaskraviy/02.webp"},
            {"id":3, "srcImg": "sliders/jkYaskraviy/03.webp"},
            {"id":4, "srcImg": "sliders/jkYaskraviy/04.webp"},
            {"id":5, "srcImg": "sliders/jkYaskraviy/05.webp"},
            {"id":6, "srcImg": "sliders/jkYaskraviy/06.webp"},
            {"id":7, "srcImg": "sliders/jkYaskraviy/07.webp"},
            {"id":8, "srcImg": "sliders/jkYaskraviy/08.webp"},
            {"id":9, "srcImg": "sliders/jkYaskraviy/09.webp"}
            ],
          
          "name_portfolioItem" : " Ремонт на Калнишевського, Новобудова",
          "location_p": "ЖК Яскравий",
          "footage_p": "27 кв. м.",
          "cost_p": "15 тис. $",
          "period_p": "3 міс.",
          "completedWorks_p": "Повне перепланування, нова електрика, сантехніка, штукатурка, тепла підлога. Ремонт квартири новобудова після будівництва.",
          "portfolioLink": "/yaskraviy"
        },
  
        {
          "id": 1.3,
          "photos": [
            {"id":1, "srcImg": "sliders/sinergiyaCity/01.webp"},
            {"id":2, "srcImg": "sliders/sinergiyaCity/02.webp"},
            {"id":3, "srcImg": "sliders/sinergiyaCity/03.webp"},
            {"id":4, "srcImg": "sliders/sinergiyaCity/04.webp"},
            {"id":5, "srcImg": "sliders/sinergiyaCity/05.webp"},
            {"id":6, "srcImg": "sliders/sinergiyaCity/06.webp"},
            {"id":7, "srcImg": "sliders/sinergiyaCity/07.webp"},
            {"id":8, "srcImg": "sliders/sinergiyaCity/08.webp"},
            {"id":9, "srcImg": "sliders/sinergiyaCity/09.webp"},
            {"id":10, "srcImg": "sliders/sinergiyaCity/10.webp"},
            {"id":11, "srcImg": "sliders/sinergiyaCity/11.webp"},
            ],
          
          "name_portfolioItem" : "Ремонт квартири в Ірпені, Новобудова",
          "location_p": "ЖК Синергія Сіті",
          "footage_p": "78 кв. м.",
          "cost_p": "12.5 тис. $",
          "period_p": "4 міс.",
          "completedWorks_p": "2 спальні, 2 санвузли, кухня-їдальня, сходи, два балкони. Штукартука, шпаклівка, плиточні роботи, фарбування, гіпсокартівні роботи.",
          "portfolioLink": "/sinergiyacity"
        },
  
        {
          "id": 1.4,
          "photos": [
            {"id":1, "srcImg": "sliders/varshavskyi/01.webp"},
            {"id":2, "srcImg": "sliders/varshavskyi/02.webp"},
            {"id":3, "srcImg": "sliders/varshavskyi/03.webp"},
            {"id":4, "srcImg": "sliders/varshavskyi/04.webp"},
            {"id":5, "srcImg": "sliders/varshavskyi/05.webp"},
            {"id":6, "srcImg": "sliders/varshavskyi/06.webp"},
            ],
          
          "name_portfolioItem" : "Ремонт квартири в ЖК Варшавський, Новобудова",
          "location_p": "ЖК Варшавський",
          "footage_p": "40.1 кв. м.",
          "cost_p": "870 тис. грн.",
          "period_p": "9 міс.",
          "completedWorks_p": "Ремонт в новобудові за адресою:  ЖК Варшавський було виконано: з 15.03.2022 - 12.12.2023. Вартість всіх робіт та чорнових матеріалів склала  870000. Площа квартири склала 40,1 м.кв.",
          "portfolioLink": "/varshavskiy"
        },
  
        {
          "id": 1.5,
          "photos": [
          {"id":1, "srcImg": "sliders/dubeshnya/01.webp"},
                {"id":2, "srcImg": "sliders/dubeshnya/02.webp"},
                {"id":3, "srcImg": "sliders/dubeshnya/03.webp"},
                {"id":4, "srcImg": "sliders/dubeshnya/04.webp"},
                {"id":5, "srcImg": "sliders/dubeshnya/05.webp"},
                {"id":6, "srcImg": "sliders/dubeshnya/06.webp"},
                {"id":7, "srcImg": "sliders/dubeshnya/07.webp"},
                {"id":8, "srcImg": "sliders/dubeshnya/08.webp"},
                {"id":9, "srcImg": "sliders/dubeshnya/09.webp"},
            ],
            "name_portfolioItem" : "Ремонт приватного будинку в Дубечні",
            "location_p": "с. Дубечня",
            "footage_p": "140 кв. м.",
            "cost_p": "66.5 тис. $",
            "period_p": "12 міс.",
            "completedWorks_p": "Ремонт новобудови приватного будинку. Дві спальні, два санвузла, вітальня, кухня, передпокій, сервісні приміщення. Двері прихованого монтажу, деревʼяна підлога та сходи, сучасна італійська плитка під замовлення, фасадні роботи - використання клінкерної плитки та штукатурки. Дах - натуральна черепиця.",
            "portfolioLink": "/dubechnya"
        },
  
        ]
    }

},
    methods: {
        getImgUrl(pic) {
        return require('@/assets/' + pic);
        },
        openFullScreen(index) {
            this.isFullScreen = true;
            this.fullScreenImage = this.portfolioImages[index]; // Встановлюємо fullScreenImage
            document.body.style.overflow = 'hidden';
            },


        closeFullScreen() {
        this.isFullScreen = false;
        this.fullScreenImage = null; // Очищати обране зображення при закритті
        document.body.style.overflow = '';
        }  
        
    }

}
</script>

<style scoped>

.portfolioComponentSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin-top: 130px;
    width: 100%;

}

.portfolioImages {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    align-items: center;
    padding: 100px;
}

.portfolioImages img {
    width: 280px;
    height: 450px;
}

.options {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 50px;
    padding-top: 30px;
    width: 750px;
}

.name_portfolioItem{
    color: var(--SecondColor, #322929);
    font-size: 20px;
    font-weight: 700;
    text-transform: uppercase;
    width: 500px;
    text-align: center;
  }

  .optionIcon {
    width: 45px;
    height: 45px;
    flex-shrink: 0;
  }

  .optionItem {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    justify-content: center;
  }

  .optionList {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 35px;
  }

  .h1_completedWorks {
    color:  #322929;
    font-size: 20px;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
  }

  .completedWorks_p {
    width: 525px;
    flex-shrink: 0;
    text-align: center;
  }

  .optionP {
    color: #322929;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
  }

  .anotherItems {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
  }


  .dividedLine {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    background: rgba(50, 41, 41, 0.8);
    height: 100px;
    color: white;
  }


@media (max-width: 430px) {
    .header {
      background: #322929;
      height: 100px;
      width: 100%;
    }


.portfolioImages {
    padding: 10px;
    align-items: center;
    justify-content: center;
}


  .options {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    padding-top: 30px;
    width: 350px;
  }

  .right-side p, .right-side h1 {
    text-align: center;
  }


  .name_portfolioItem{
    font-size: 20px;
    width: 300px;
    text-align: center;
  }


  .h1_completedWorks {
    font-size: 20px;
    text-align: center;
  }

  .completedWorks_p {
    width: 325px;
    flex-shrink: 0;
    text-align: center;
  }

  .optionP {
    font-size: 16px;
    text-align: center;
  }
  }

  @media (min-width: 431px) and (max-width: 1024px) {


.portfolioImages {
    padding: 50px;
}

  .options {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    padding-top: 30px;
    width: 350px;
  }

  .right-side p, .right-side h1 {
    text-align: center;
  }


  .name_portfolioItem{
    font-size: 20px;
    width: 300px;
    text-align: center;
  }


  .h1_completedWorks {
    font-size: 20px;
    text-align: center;
  }

  .completedWorks_p {
    width: 325px;
    flex-shrink: 0;
    text-align: center;
  }


}

.fullScreenOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fullScreenImage img {
  max-width: 80%;
  max-height: 90vh;
}

.closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  z-index: 1000;
}

</style>