import { createApp } from 'vue'
import App from './App.vue'
import components from '@/components/UI'
import VueSplide from '@splidejs/vue-splide';
import PrimeVue from 'primevue/config';
import VueLazyLoad from 'vue3-lazyload';
import VueGlide from 'vue-glide-js';


import { library } from '@fortawesome/fontawesome-svg-core';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import router from '@/router'
import VueTheMask from 'vue-the-mask'

import Button from "primevue/button"

library.add(faChevronDown);






const app = createApp(App)
app.use(VueGlide);
app.use(VueLazyLoad);
app.use( VueSplide );
app.use( PrimeVue );
app.use(router);
app.use(VueTheMask)



components.forEach(component => {
  app.component(component.name, component),
  app.component('font-awesome-icon', FontAwesomeIcon),
  app.component('PrimeButton', Button);
})



app.mount('#app')
