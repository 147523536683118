<template>
  <div>
    <HeaderSite></HeaderSite>
  </div>
  <section class="calcPage">
    <CalculatorApp></CalculatorApp>
  </section>
  <footer-site></footer-site>
</template>

<script>
import CalculatorApp from '@/components/MainPage/09CalculatorBlock.vue'
export default {
  components: {
    CalculatorApp
  }
}
</script>

<style scoped>

.calcPage {
  margin-top: 150px;
}
  .header .headerMenu menu :nth-child(5) {
    color: #ED7C0E;
  }

  @media (max-width: 430px) {
    .header {
      background: #322929;
      height: 100px;
      width: 100%;
    }

      .calcPage {
        margin-top: 120px;
      }
  }



</style>