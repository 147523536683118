<template>
    <form action="sendForm.php" method="post">
      <select name="option" class="selectMessangers" id="selectM">
        <option value="WhatsApp" selected>WhatsApp</option>
        <option value="Telegram">Telegram</option>
        <option value="Viber">Vider</option>
      </select>
      <div class="numberBox">
        <div class="countryPref">
          +380
        </div>
        <input type="tel" class="phone" placeholder="Введіть номер телефону" name="tel" v-mask="'## ### ####'" pattern="[0-9]{2} [0-9]{3} [0-9]{4}" required title="Ви ввели номер некоректно">
      </div>
      <main-button id="submit2" class="send-form">Залишити заявку</main-button>
    </form>
    <div class="messangerContact" style="display: flex; gap: 5px;">
          <a href="https://wa.me/380675481288" target="_blank">
            <img class="m" src="@/assets/icons/contactIcons/waContact.png" alt="WhatsApp" width="45px">
          </a>

          <a href="https://t.me/+380675481288" target="_blank">
            <img class="m" src="@/assets/icons/contactIcons/tgContact.png" alt="Telegram" width="45px">
          </a>

          <a href="viber://chat?number=%2B380675481288" target="_blank">
            <img class="m" src="@/assets/icons/contactIcons/vContact.png" alt="Viber" width="45px">
          </a>
        </div>
    
</template>

<script>


export default {
  name: 'MainForm2',
  mounted() {
    if (window.location.href.includes('/sendForm')) {
      window.gtag('event', 'conversion', {
        'send_to': 'AW-11367489649/CyxTCIKPg7cZEPHAuKwq'
      });
    }
  }
  
}
</script>

<style scoped>
   .selectMessangers, .phone {
    width: 300px;
    height: 60px;
    border: 0.5px solid #322929;
    border-radius: 2px;
    padding-left: 10px;
    /* padding: 0 15px; */

  }

  .phone {
    border-left: none;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    border: 2px solid #322929;
    height: 57px;

  }

  .selectMessangers {
    width: 310px;
  }

  #submit2 {
    width: 300px;
  }

  form {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    align-items: center;

  }


  select, select option {
    color: #322929;
  }

  .numberBox {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 63px;
    width: 300px;
  }

  .countryPref {
    height: 100%;
    border: 0.5px solid #322929;
    border-bottom-left-radius:  2px;
    border-top-left-radius:  2px;
    border-right: none;
    background: #322929;
    color: white;
    padding: 0 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 15px;
    margin-right: -1px;
  }


  @media (max-width: 430px) {
    form {
    justify-content: center;
  }

  .selectMessangers, .phone, #submit2,   .numberBox {
    width: 100%;
  }


}

</style>