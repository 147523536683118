<template>
  <section class="priceBlock">
    <h1 class="main_title" style="max-width: 650px; text-align: center;">Дизайн, ремонт та будівництво під будь-який бюджет та цілі</h1>
    <div class="priceList">
      <div v-for="(category, index) in categories" :key="index" class="priceList_item">
        <p class="titleItem">{{ category.title }}</p>
        <p class="subtitleItem">{{ category.subtitle }}</p>
        <p class="priceItem">{{ category.price }}</p>
        <div class="options">
          <div v-for="(item, itemIndex) in category.items" :key="itemIndex" class="checkedItem">
            <img src="@/assets/icons/cheked.svg" alt="">
            <p class="checkedItem_text">{{ item }}</p>
          </div>
        </div>
        <MainForm2 class="mf2"></MainForm2>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'PriceBlock',
  data() {
    return {
      categories: [
        {
          title: 'Дизайн',
          subtitle: 'Дизайн-проект',
          price: 'Від 750 грн/м.кв.',
          items: [
            'Обмірювальний план',
            'План демонтажу',
            'План монтажу',
            'План розміщення меблів',
            'План стель',
            'План підлогових покриттів',
            'План дверних отворів'
          ]
        },
        {
          title: 'Ремонт',
          subtitle: 'Ремонт квартир, будинків, комерційний приміщень',
          price: 'Від 3699 грн/м.кв.',
          items: [
            'Виготовлення схем та креслень',
            'Підготовчий етап',
            'Демонтаж',
            'Монтаж',
            'Під ключ'
          ]
        },
        {
          title: 'Будівництво',
          subtitle: 'Будівництво домів та інших видів приміщень',
          price: 'Від 9990 грн/м.кв.',
          items: [
            'Вивчення документації',
            'Підготовка ділянки',
            'Коробка будинку',
            'Фасад, вікна та двері',
            'Паркан',
            'Ландшафтний дизайн',
            'Внутрішній ремонт'
          ]
        }
      ]
    };
  }
};
</script>

<style scoped>

.mf2{
  flex-direction: column;
}


.priceBlock {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 100px;
  margin: 100px 100px;
}
  .main_title {
    text-align: center;
    color: #322929;
    font-size: 40px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    text-transform: uppercase;
  }

  .priceList {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    align-items:top;
    justify-content: center;
  }

  .priceList_item {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    border-radius: 2px;
    border: 1px solid #ECF1F8;
    padding: 30px 10px;
  }

  .titleItem {
    color:  #322929;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

.subtitleItem {
  color:  #322929;
  min-height: 50px;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.priceItem {
  color: #FFF;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 60px; /* 375% */
  padding: 0px 50px;
  border-radius: 2px;
  background: #322929;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.40);
}

.checkedItem {
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
}

.checkedItem img{
  width: 24px;
  height: 24px;
}

.options {
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: flex-start;
  padding:15px;
  height: 290px;
}


@media (max-width: 430px) {
  .main_title {
    font-size: 26px;
    width: 350px;
  }

  .priceBlock {
    margin: 80px 20px;
  }

  .options {
    height: auto;
  }
}


</style>