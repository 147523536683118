<template>
    <section class="interiors">
      <div class="olegdiv">
        <img src="@/assets/oleg/dianaCircle.webp" alt="" class="photoOlega">
        <div class="textOleg">
          <h1 class="h1">Створимо унікальний дизайн інтер'єру</h1>
          <p class="p1">Дизайнерський ремонт квартир за фіксовану вартість</p>
        </div>
      </div>
      <div ref="slick" class="interiorsView">
        <div v-for="(pic, index) in picInteriors" :key="index" @click="openFullScreen(index)">
          <img :src="getImgUrl(pic.srcImg)" alt="">
        </div>
  
      </div>
        <p class="garantText">Ви отримаєте саме те, чого очікували, а також гарантію робіт на 1 рік!</p>
        <p class="textDesign">А якщо ви ще не маєте остаточних ідей - наша професійна команда спланує для вас дизайн-проект:</p>
        
        <div class="qualityBtns">
            <div class="qItems"> 
                <div class="circle"></div>
                <p class="animate__animated animate__pulse">Згідно ваших смаків та потреб</p>
            </div>

            <div class="qItems">
                <div class="circle"></div>
                <p class="animate__animated animate__pulse">згідно вашого бюджету та побажань</p>
            </div>
        </div>

      <div v-if="isFullScreen" class="fullScreenOverlay" @click="closeFullScreen">
        <div class="fullScreenImage">
          <img :src="getImgUrl(fullScreenImage.srcImg)" alt="">
          <button class="closeButton" @click="closeFullScreen">Close</button>
        </div>
      </div>
    </section>
  </template>
  
  <script>
  import 'slick-carousel/slick/slick.css'; // Import slick carousel CSS
  import 'slick-carousel/slick/slick-theme.css'; // Import slick carousel theme CSS
  import $ from 'jquery';
  import 'slick-carousel';
  
  export default {
    name: 'designsComponent',
    data() {
      return {
        picInteriors: [
        {"id":"01", "srcImg": "sliders/designs/07.webp"},
          {"id":"02", "srcImg": "sliders/designs/02.webp"},
          {"id":"03", "srcImg": "sliders/designs/03.webp"},
          {"id":"04", "srcImg": "sliders/designs/04.webp"},
          {"id":"05", "srcImg": "sliders/designs/05.webp"},
          {"id":"06", "srcImg": "sliders/designs/06.webp"},
          {"id":"07", "srcImg": "sliders/designs/01.webp"},
          {"id":"08", "srcImg": "sliders/designs/08.webp"},
          {"id":"09", "srcImg": "sliders/designs/09.webp"},
          {"id":"10", "srcImg": "sliders/designs/10.webp"},
          {"id":"11", "srcImg": "sliders/designs/11.webp"},
          {"id":"12", "srcImg": "sliders/designs/12.webp"},
          {"id":"13", "srcImg": "sliders/designs/13.webp"},
          {"id":"14", "srcImg": "sliders/designs/14.webp"},
          {"id":"15", "srcImg": "sliders/designs/15.webp"},
          {"id":"16", "srcImg": "sliders/designs/16.webp"},
          {"id":"17", "srcImg": "sliders/designs/17.webp"},
        ],
        isFullScreen: false,
        fullScreenIndex: null
      }
    },
    methods: {
      getImgUrl(pic) {
        return require('@/assets/' + pic);
      },
      openFullScreen(index) {
        this.isFullScreen = true;
        this.fullScreenImage = this.picInteriors[index]; // Встановлюємо fullScreenImage
        document.body.style.overflow = 'hidden';
      },
  
      closeFullScreen() {
        this.isFullScreen = false;
        this.fullScreenImage = null; // Очищати обране зображення при закритті
        document.body.style.overflow = '';
      }  
    },
    mounted() {
      $(this.$refs.slick).slick({
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
            },
          },
        ],
      });
    },
  }
  </script>
  
  <style scoped>
 .qualityBtns {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
  }

.circle {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: #ED7C0E;
  margin-right: -10px;
  z-index: 20;
}

.qItems {
  display: flex;
  background: #ED7C0E;
  border-radius: 30px 0 0 30px;
  width: 300px;
}
.qItems p {
    color: white;
    padding: 15px 20px;
    border-radius: 2px;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
}

  
  .interiors {
    display: flex;
    flex-direction: column;
    gap: 30px;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
    margin-top: 100px;
    background: #322929;
    padding: 50px;
    color: white;
  }
  
  .photoOlega {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    box-shadow: 0 0 3px white;
  }
  
  .olegdiv {
    display: flex;
    gap: 30px;
    align-items: center;
    flex-wrap: wrap;
  }
  
  .textOleg {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 600px;
  }
  
  .textOleg h1 {
    font-size: 40px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    color: white;
  }
  
  .textOleg p {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color: white;
  }
  
  .interiorsView {
    display: flex;
    width: 1100px;
  }
  
  .interiorsView img {
    cursor: pointer;
    width: 100%;
    height: 400px;
    margin: 0 auto;
    padding: 5px;
  }
  
  h2 {
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    text-transform: uppercase;
    color: white;
    max-width: 700px;
  }
  
  .p2 {
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    color: white;
  }
  
  @media (max-width: 430px) {
  
    .interiorsView {
    width: 100%;
  }
  
  .interiorsView img {
    width: 100%;
    height: 400px;
  }
  
    .interiors {
      gap: 20px;
      margin-top: 50px;
      padding: 40px;
      width: 100%;
    }

  
    .olegdiv {
      flex-direction: column;
      gap: 20px;
    }
  
    .textOleg {
      width: 330px;
      align-items: center;
      justify-content: center;
      text-align: center;
    }
  
    .textOleg h1 {
      font-size: 28px;
      text-transform: uppercase;
      max-width: 330px;
      text-align: center;
    }
  
    .textOleg p {
      font-size: 15px;
      text-align: center;
    }
  
  }
  
  @media (min-width: 431px) and (max-width: 1024px) {
  
    .interiorsView {
    width: 100%;
  }
  
  .interiorsView img {
    width: 100%;
   
  }
  
    .interiors {
      width: 100%;
    }

  
    .olegdiv {
      flex-direction: column;
      justify-content: center;
      text-align: center;
    }
  
    .textOleg {
      align-items: center;
      justify-content: center;
      text-align: center;
    }
  
    .textOleg h1 {
      text-align: center;
      text-transform: uppercase;
    }
  
    .textOleg p {
      text-align: center;
    }
  
  }
  
  
  .fullScreenOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .fullScreenImage img {
    max-width: 90%;
    max-height: 90vh;
  }
  
  .closeButton {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: transparent;
    border: none;
    color: #fff;
    font-size: 20px;
    cursor: pointer;
    z-index: 1000;
  }
  </style>
  