<template>
  <button class="main-btn">
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: 'main-button'
}
</script>

<style scoped>
  .main-btn {
    width: 300px;
    height: 60px;
    color: #FFF;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 60px;
    border-radius: 2px;
    background: #ED7C0E;
    border: none;
    cursor: pointer;
  }

</style>