<template>
  <section class="mp">
    <HeaderSite></HeaderSite>
      <MainScreen></MainScreen>
      <OurClientsList></OurClientsList>
      <InteriorExamples></InteriorExamples>
      <!-- <PriceList></PriceList> -->
      <PortfolioView></PortfolioView>
      <AboutUs></AboutUs>
      <designsComponent></designsComponent>
      <AdvantagesBlock></AdvantagesBlock>
      <VideoReviews></VideoReviews>
      <CalculatorApp></CalculatorApp>
      <FormBlock></FormBlock>
      <PhotoReviews></PhotoReviews>
      <AccordionBlock></AccordionBlock>
      <StagesBlock></StagesBlock>
      <PriceBlock></PriceBlock>
      <OtherServices></OtherServices>
      <footer-site></footer-site>
  </section>
</template>

<script>
import HeaderSite from '@/components/HeaderSite.vue'
import MainScreen from '@/components/MainPage/01MainScreen.vue'
import CalculatorApp from '@/components/MainPage/09CalculatorBlock.vue'
import OurClientsList from '@/components/MainPage/02OurClients.vue'
import InteriorExamples from '@/components/MainPage/03InteriorExamples.vue'
import PortfolioView from '@/components/MainPage/04PortfolioBlock.vue'
import AboutUs from '@/components/MainPage/05AboutUs.vue'
import AdvantagesBlock from '@/components/MainPage/07AdvantagesBlock.vue'
import VideoReviews from '@/components/MainPage/08VideoReviews.vue'
import FormBlock from '@/components/MainPage/10FormBlock.vue'
import PhotoReviews from '@/components/MainPage/11PhotoReviews.vue'
import AccordionBlock from '@/components/MainPage/12AccordionBLock.vue'
import StagesBlock from '@/components/MainPage/13StagesBlock.vue'
import PriceBlock from '@/components/MainPage/15PriceBlock.vue'
import OtherServices from '@/components/MainPage/14OtherServices.vue'
import designsComponent from '@/components/completedWorks/designComponent/designsComponent.vue'

// import PriceList from '@/components/MainPage/PriceList.vue'


export default {
  components: {
    HeaderSite,
    MainScreen,
    CalculatorApp,
    OurClientsList,
    InteriorExamples,
    PortfolioView,
    AboutUs,
    AdvantagesBlock,
    VideoReviews,
    FormBlock,
    PhotoReviews,
    AccordionBlock,
    StagesBlock,
    PriceBlock,
    OtherServices,
    designsComponent
    // PriceList

  },
}
</script>

<style scoped>
.mp {
  width: 100%;
}

@media (max-width: 410px) {
  .mp {
    padding-top: 80px;
  }
}
</style>