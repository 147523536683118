<template>
    <menu class="menu">
      <router-link to="/">
        <a href="">Головна</a>
      </router-link>

      <router-link to="/about">
        <a href="">Про нас</a>
      </router-link>

      <router-link to="/portfolio">
        <a href="">Приклади робіт</a>
      </router-link>

      <router-link to="/reviews">
        <a href="">Відгуки</a>
      </router-link>

      <router-link to="/calculator">
        <a href="">Калькулятор</a>
      </router-link>

      <router-link to="/now">
        <a href="">Проекти в роботі</a>
      </router-link>

      <router-link to="/fasting">
        <a href="">Відділ постачання</a>
      </router-link>

      <router-link to="/contacts">
        <a href="">Контакти</a>
      </router-link>

    </menu>
</template>

<script>
export default {
  name: 'MainMenu',
  
}
</script>

<style scoped>
  menu {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: baseline;
    width: 500px;
  }

  menu a {
    color: #ffffff;

    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-decoration: none;
  }

  menu a:hover {
    color: #ED7C0E;
  }
</style>