<template>
  <section>
    <div>
      <HeaderSite></HeaderSite>
    </div>
    <InteriorExamples class="interiors"></InteriorExamples>
    <PortfolioView></PortfolioView>
  </section>
  <FooterSite></FooterSite>
</template>

<script>
import PortfolioView from '@/components/MainPage/04PortfolioBlock.vue'
import InteriorExamples from '@/components/MainPage/03InteriorExamples.vue'

export default {
  components: {
    PortfolioView,
    InteriorExamples
  }
}
</script>

<style scoped>
  .header .headerMenu menu :nth-child(3) {
    color: #ED7C0E;
  }

  .interiors {
    margin: 115px 0;
  }

  @media (max-width: 430px) {
    .header {
      background: #322929;
      height: 100px;
      width: 100%;
    }
    .interiors {
        margin: 100px 0;
      }
  }



</style>