<template>
  <section>
      <HeaderSite></HeaderSite>
    <div class="telegramLink">
      <p><span>Переходьте на наш телеграм канал,</span> де ми публікуємо проекти в роботі та хід ремонту, будівництва наших обʼєктів</p>
      <a href="https://t.me/roskinbud">
        <img class="tgImg" src="@/assets/icons/TelegramLink.svg" alt="">
      </a>
      <h2>Перейти в телеграм</h2>
    </div>
  </section>
  <footer-site></footer-site>
</template>

<script>
export default {

}
</script>

<style scoped>
  .header .headerMenu menu :nth-child(6) {
    color: #ED7C0E;
  }

  .telegramLink {
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
  background: hwb(0 16% 81%);
  margin-top: 115px;
}

.telegramLink p {
  width: 500px;
    color: white;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.telegramLink p span {
  color:  #ED7C0E;
}

.tgImg {
  width: 100px;
  height: 100px;
  color: white;
}

.telegramLink h2 {
  color:  white;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
}



@media (max-width: 430px) {
  .telegramLink p {
  width: 300px;
  }
  .header {
      background: #322929;
      height: 100px;
      width: 100%;
    }

    .telegramLink {
      margin-top: 100px;
    }
}

@media (min-width: 431px) and (max-width: 1024px) {
  .telegramLink p {
  width: 500px;
  }
  .header {
      background: #322929;
      height: 100px;
      width: 100%;
    }

}

</style>