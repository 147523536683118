<template>
    <div>
      <PhoneMenu class="phoneMenu"></PhoneMenu>
     <router-view></router-view>
  </div>
</template>

<script>
import PhoneMenu from '@/components/UI/MobileMenu.vue'


export default {
  name: 'App',
  data() {
    return {
        inputMessenger: '',
        inputNumber: ''
    }
  },
  components: {
    PhoneMenu,
  },
}
</script>

<style>
@import '@/styles/fonts.css';

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  font-family: Gilroy, Helvetica, sans-serif;
  margin: 0;
  padding: 0;
  border: 0;
  box-sizing: border-box;
}


.phoneMenu {
      display: none;
    }


@media (max-width: 430px) {


  .phoneMenu {
        position: fixed;
        display: flex;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100px;
        justify-content: space-around;
        align-items: center;
        background: #322929;
        z-index: 101;
      }
}
</style>

