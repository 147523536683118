<template>
  <section class="otherServices">
    <h1 class="otherServices_title">Повний перелік послуг, які ми надаємо</h1>

    <div class="otherServices_list">
      <div class="otherServices_item">
        <img class="imgItem" src="@/assets/sliders/designs/09.webp" alt="">
        <div class="middle">
          <h2>Дизайн інтерʼєру</h2>
          <ul>
            <div class="tag">квартир</div>
            <div class="tag">будинків, котеджів</div>
            <div class="tag">офісу</div>
            <div class="tag">інших приміщень</div>
          </ul>
        </div>
      </div>

      <div class="otherServices_item">
        <img class="imgItem" src="@/assets/sliders/dubeshnya/06.webp" alt="">
        <div class="middle">
          <h2>Ремонт</h2>
          <ul>
            <div class="tag">під ключ (євро, капітальний, дизайнерський, елітний)</div>
            <div class="tag">1-5 кімнатних квартир</div>
            <div class="tag">окремих кімнат</div>
            <div class="tag">домів, таунхаусів</div>
            <div class="tag">комерційних обʼєктів(офіс, кафе, магазин, ресторан, салони тд.)</div>
          </ul>
        </div>
      </div>

      <div class="otherServices_item">
        <img class="imgItem" src="@/assets/sliders/zapovitniy/01.webp" alt="">
        <div class="middle">
          <h2>Будівництво</h2>
          <ul>
            <div class="tag">котеджів</div>
            <div class="tag">каркасних будинків</div>
            <div class="tag">1-3 поверхових будинків</div>
            <div class="tag">будинків із сіп-панелей</div>
            <div class="tag">будинків з газоблоку</div>
            <div class="tag">будинків з цегли</div> 
            <div class="tag">елітних будинків</div>
            
          </ul>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'OtherServices',
  data() {
    return {

    };
  },
  methods: {
    
  },

};
</script>

<style scoped>
  .otherServices {
    display: flex;
    flex-direction: column;
    gap: 30px;
    align-items: center;
    justify-content: center;
    margin: 50px 5vw; /* Изменено значение для адаптивных отступов */
  }

  .otherServices_title {
    text-align: center;
    color: #322929;
    font-size: 40px; /* Адаптивный размер шрифта */
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    text-transform: uppercase;
    margin-bottom: 50px;
  }

  .otherServices_list {
    display: flex;
    flex-direction: column;
    gap: 50px;
    align-items: center;
    justify-content: center;
  }

  .otherServices_item {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 50px;
  }

  .imgItem {
    height: 130px;
    width: 80px;
    border-radius: 40px;
    transform: rotate(15deg);
  }

  

  .middle {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 20px;
    width: 500px;
  }

  .middle h2 {
    color: #322929;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    padding-top: 10px;
  }

  .middle ul .tag {
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    background: #322929;
    color: white;
    padding: 8px;
    border-radius: 2px;
  }

  .middle ul  {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    align-items: flex-start;
  }

  .middle p {
    max-width: 300px;
  }


@media (max-width: 430px) {
  .otherServices {
    margin: 50px 10px;
  }

  .otherServices_title {
    font-size: 26px;
  }

  .middle {
    align-items: center;
    justify-content: center;
  }

  .middle ul {
    align-items: center;
    justify-content: center;
    width: 350px;
  }

  .otherServices_item {
    max-width: 390px;
  }
}

</style>
