import {createRouter, createWebHistory} from "vue-router";
import MainPage from "@/pages/MainPage.vue";
import AboutPage from '@/pages/AboutPage.vue';
import PortfolioPage from '@/pages/PortfolioPage.vue';
import ReviewsPage from '@/pages/ReviewsPage.vue';
import CalculatorPage from '@/pages/CalculatorPage.vue';
import NowPage from '@/pages/NowPage.vue';
import FastingPage from '@/pages/FastingPage.vue';
import ContactsPage from '@/pages/ContactPage.vue';
import DubechnyaComponent from '@/components/completedWorks/PotrfolioComponents/dubechnyaComponent.vue'
import ZapovitniyComponent from '@/components/completedWorks/PotrfolioComponents/zapovitniyComponent.vue'
import faynaTownComponent from '@/components/completedWorks/PotrfolioComponents/faynaTownComponent.vue'
import sinergiyaCityComponent from '@/components/completedWorks/PotrfolioComponents/synergiyaCityComponent.vue'
import varshavskiyComponent from '@/components/completedWorks/PotrfolioComponents/varshavskiyComponent.vue'
import yaskraviyComponent from '@/components/completedWorks/PotrfolioComponents/yaskraviyComponent.vue'

// 2. Define some routes
// Each route should map to a component.
// We'll talk about nested routes later.
const routes = [
  { path: '/', component: MainPage },
  { path: '/about', component: AboutPage },
  { path: '/portfolio', component: PortfolioPage },
  { path: '/reviews', component: ReviewsPage },
  { path: '/calculator', component: CalculatorPage },
  { path: '/now', component: NowPage },
  { path: '/fasting', component: FastingPage },
  { path: '/contacts', component: ContactsPage },
  { path: '/dubechnya', component: DubechnyaComponent },
  { path: '/zapovitniy', component: ZapovitniyComponent },
  { path: '/faynatown', component: faynaTownComponent },
  { path: '/sinergiyacity', component: sinergiyaCityComponent },
  { path: '/varshavskiy', component: varshavskiyComponent },
  { path: '/yaskraviy', component: yaskraviyComponent },
]

// 3. Create the router instance and pass the `routes` option
// You can pass in additional options here, but let's
// keep it simple for now.
const router = createRouter({
  // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
  history: createWebHistory(),
  routes, // short for `routes: routes`
  scrollBehavior() {
    return { top: 0 }; // Прокручуємо сторінку до верхньої частини при кожному переході
  }
});

export default router;