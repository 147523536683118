<template>
  <div></div>
    <!-- <form class="mainForm" action="sendForm.php" method="post" :id="idForm">
        <label for="">Тип приміщеня:
          <select name="option" required id="typeOfRoom">
            <option value="Квартира" selected>Квартира</option>
            <option value="Будинок">Будинок</option>
            <option value="Комерційна нерухомість">Комерційна нерухомість</option>
          </select>
        </label>
      <label for="">Км. метрів:
        <input type="number" name="number" id="SqMeters" :value="SqMetersValue" required>
      </label>
      <label>
        <input type="tel" name="tel" id="tel" placeholder="Введіть номер" required>
      </label>
      <main-button  id="submit" class="send-form">Отримати консультацію</main-button>
    </form> -->
</template>

<script>



export default {
  name: 'MainForm',
  props: {
    "idForm": {
      type: String,
      default: "mainForm"
  },
  SqMetersValue: {
    type: Number,
    default: 40
  }
}
}
</script>

<style scoped>
  .mainForm {
    display: flex;
    gap: 30px;
    flex-wrap: wrap;
    align-items: flex-end;
  }


  #typeOfRoom, #SqMeters, #tel {
    min-width: 285px;
    padding: 0 0 0 15px;
    height: 60px;
    border-radius: 2px;
    background: #ECF1F8;
    color: #322929;
    border: none;
    overflow: hidden;
    outline:none;
   }

   #typeOfRoom {
    width: 300px;
   }


label {
  display: flex;
  flex-direction: column;
  gap: 15px;

  align-items: flex-start;

}


  @media (max-width: 430px) {
    .mainForm {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 30px;
  }
   }


   @media (min-width: 431px) and (max-width: 1020px) {
    .mainForm {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
    padding-bottom: 30px;
  }
   }

</style>