<template>
    <section class="imagesSection">
      <div class="titleImg">
        <img :src="currentImgUrl" alt="img1">
      </div>
  
      <div class="miniImg">
        <img v-for="(slide, index) in slides.slice(1, 6)" :key="index" :src="getImgUrl(slide.srcImg)" :alt="'img' + (index + 2)" @click="changeImage(slide.srcImg)">
      </div>
    </section>
  </template>
  
  <script>
  export default {
    name: 'imageSectionMini',
    props: {
      slides: Array
    },
    data() {
      return {
        currentImgUrl: this.getImgUrl(this.slides[0].srcImg) // Початкове значення для великої картинки
      };
    },
    methods: {
      getImgUrl(pic) {
        return require('@/assets/' + pic);
      },
      changeImage(srcImg) {
        this.currentImgUrl = this.getImgUrl(srcImg); // Змінюємо URL поточної великої картинки на URL клікнутої мініатюри
      }
    }
  }
  </script>
  
  <style scoped>
  .imagesSection {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
  }
  
  .titleImg img {
    width: 270px;
    height: 380px;
  }
  
  .miniImg {
    display: flex;
    flex-direction: column;
    gap: 4px;
    align-items: baseline;
    height: 380px;
    overflow: hidden;
  }
  
  .miniImg img {
    width: 60px;
    height: 80px;
  }

  @media (max-width: 430px) {
    .imagesSection {
        width: 100%;
    }
  }
  </style>
  