<template>
  <div>
    <HeaderSite></HeaderSite>
  </div>
    <section class="aboutPage">
    <div class="topBlock">
      <h1 class="title"><span>Задаємо стандарти на ринку</span> ремонту та будівництва</h1>
      <div class="OlegAppeal">
        <div class="leftSide">
          <img src="@/assets/oleg/3.webp" alt="">
          <p>Олег роскін, власник “Roskin”</p>
        </div>
      <div class="rightSide">
        <p class="rightSide_p1 animate__animated">«особисто контролюю та приймаю усі роботи своїх команд на обʼєктах»</p>
        <p class="rightSide_p2 animate__animated"><span>На кожному з етапів робіт</span> я буду вам допомогати з питань:</p>
        <ul class="ul animate__animated">
          <li>Як визначитись з плануванням приміщення</li>
          <li>Як уникнути помилок з питань вибору матеріалів</li>
          <li>Які сучасні рішення використати на вашому обʼєкті</li>
          <li>Як розумно організувати вільний простір</li>
        </ul>
      </div>
    </div>
    </div>


    <div class="text">
      <h1>Команда Roskin повністю організовує та керує усіма процесами будівництва відповідно до договору підряду та затвердженими проєктами будівництва та дизайну. Координує роботу постачальників та субпідрядних організацій. Несе відповідальність перед замовником за ризики, результати, строки та якість виконаних робіт.</h1>
      <p>Roskin як підрядник виконує наступні обов’язки:</p>
      <ul>
        <li>Розробка та контроль виконання графіка будівництва</li>
        <li>Керування бюджетом проекту</li>
        <li>Технічний нагляд та координація виконання робіт субпідрядними організаціями</li>
        <li>Контроль за виконанням технологій виконання робіт</li>
        <li>Дотримання техніки безпеки та правил пожежної безпеки</li>
        <li>Дотримання правил охорони навколишнього середовища</li>
        <li>Передача завершеного об’єкта будівництва в експлуатацію</li>
        <li>Здача завершеного об’єкта будівництва замовнику</li>
      </ul>

      <h1>Будівництво та ремонт</h1>
      <p>Ми маємо чітке розуміння процесів проєктування, дизайну та виконання робіт із використання творчого підходу на кожному етапі.</p>
      <p>Ціль компанії – завершення кожного проєкту вчасно, не виходячи за межі бюджету, підтримуючі чітку комунікацію на всіх етапах. Наша команда співпрацює із великим пулом досвідчених архітекторів, дизайнерів та виконавців робіт, кожен з яких є професіоналом у своїй галузі. Таким чином, ми здатні створювати унікальні проєкти у будь-якій галузі будівництва.</p>
      
      <p>Наша команда у співпраці із досвідченими підрядниками пропонує широкий спектр послуг:</p>
      <ul>
        <li>Демонтажні роботи</li>
        <li>Бетонний фундамент: стрічковий або плита</li>
        <li>Електрика та сантехніка</li>
        <li>Вентиляція та кондиціонування</li>
        <li>Штукатурка, ізоляція та роботи з гіпсокартоном</li>
        <li>Покрівельні роботи</li>
        <li>Укладання плитки та натурального каменю</li>
        <li>Паркетна підлога</li>
        <li>Фарбування</li>
      </ul>

      <h1>Комплексні ремонтно-будівельні роботи</h1>
      <p>Ремонт та наступні оздоблювальні роботи мають зробити приміщення максимально комфортним для життя та роботи. Це великий комплекс робіт, який вимагає спеціальних навичок, тому ми залучаємо до співпраці різноманітних спеціалістів: сантехніків, електриків, плиточників - облицювальників, штукатурів та інш. Після ознайомлення із передбачуваним обсягом робіт запропонуємо замовнику:</p>
      <ul>
        <li>Допомогу з вибором будівельних матеріалів, які найкраще відповідатимуть поставленим задачам, та матимуть оптимальне співвідношення ціна/якість</li>
        <li>Розрахунок необхідної кількості будівельних та оздоблювальних матеріалів</li>
        <li>Підготовка кошторису: оцінка вартості та строків виконання робіт</li>
        <li>Виконання повного комплексу робіт “під ключ”</li>
        <li>Консультація щодо догляду за встановленими покриттями</li>
        <li>Гарантія один рік на всі виконані роботи</li>
      </ul>

      <p>Результат якісного виконання робіт - повністю готове до експлуатації ергономічне приміщення, яке має естетичний вигляд.</p>
      <p>Якщо Ви хочете дізнатися вартість ремонтно - оздоблювальних робіт у Вашому помешканні - лише зателефонуйте нам або залиште заявку на виклик замірника який розрахує для Dfc схему безплатно.</p>
      <main-form></main-form>
    </div>
  </section>

  <FooterSite></FooterSite>
</template>

<script>
export default {
  components: {
    
  }
}
</script>

<style scoped>



.aboutPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 115px;
}

.topBlock {
  background: #322929;
  width: 100%;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px;
}

  .header .headerMenu .menu :nth-child(2) {
    color: #ED7C0E;
  }

  .text {
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin: 50px 100px;
  }

  h1 {
    font-size: 24px;
    font-style: normal;
    font-weight: 900;
    text-transform: uppercase;
  }

  p {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .title {
    width: 773px;
    text-align: center;
    font-size: 40px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    text-transform: uppercase;
  }
  .title span {
    color: #ED7C0E;
  }

  .OlegAppeal {
    margin-top: 50px;
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    margin-bottom: 50px;
  }


  .leftSide {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    justify-content: center;
  }

 .leftSide img {
    width: 240px;
    height: 300px;
    border-radius: 2px;
 }

 .rightSide {
  display: flex;
  flex-direction: column;
  gap: 30px;
  justify-content: center;
 }

 .rightSide_p1 {
  width: 576px;
  font-size: 20px;
  font-style: normal;
  font-weight: 250;
  line-height: normal;
  text-transform: uppercase;
  color: #322929;
 }

 .rightSide_p2 {
  width: 637px;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
 }

 .rightSide_p2 span {
  color:#ED7C0E;
 }

 .rightSide ul{
  padding: 20px;
 }

 .rightSide ul li{
  width: 411px;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
 }

 @media (max-width: 430px) {

  .aboutPage {
    margin-top: 100px;
  }

  .header {
      background: #322929;
      height: 100px;
      width: 100%;
    }
  .title {
    width: 350px;
    font-size: 26px; 
  }
  .OlegAppeal {
    margin-top: 30px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }


 .rightSide {
  align-items: center;
 }

 .rightSide_p1 {
  width: 350px;
  text-align: center;
 }

 .rightSide_p2 {
  width: 350px;
  text-align: center;
 }


 .rightSide ul li{
  width: 320px;
 }


}


@media (min-width: 431px) and (max-width: 1024px) {
  .title {
    width: 750px;
    font-size: 26px; 
  }
  .OlegAppeal {
    margin-top: 30px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }


 .rightSide {
  align-items: center;
 }

 .rightSide_p1 {
  width: 750px;
  text-align: center;
 }

 .rightSide_p2 {
  width: 750px;
  text-align: center;
 }


 .rightSide ul li{
  width: 750px;
 }


}

</style>