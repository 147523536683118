<template>
  <section class="slider">
    <!-- <Carousel id="gallery" :items-to-show="1" :wrap-around="false" v-model="currentSlide">
    <Slide class="main-slide" v-for="slide in slides" :key="slide.id">
      <div class="carousel__item"> -->
        <!-- <img :src="getImgUrl(slide.srcImg)"> -->
        <div id="main">
          <div class="attach">
              <div class="slider_case">
                  <v-slider v-bind="options">
                      <div class="item" v-for="slide in slides" :key="slide.id">
                        <img :src="getImgUrl(slide.srcImg)" alt="">
                      </div>
                  </v-slider>
              </div>
          </div>
      </div>
      <!-- </div>
    </Slide>
  </Carousel> -->

  <!-- <Carousel
    id="thumbnails"
    :items-to-show="4"
    :wrap-around="true"
    v-model="currentSlide"
    ref="carousel"
  >
    <Slide v-for="slide in slides" :key="slide.id">
      <div class="carousel__item carousel__item__bottom" @click="slideTo(slide.id - 1)">
        <img :src="getImgUrl(slide.srcImg)" alt="">
      </div>
    </Slide>
  </Carousel> -->
  </section>
  
</template>

<script>
import { defineComponent } from 'vue';
// import { Carousel, Slide } from 'vue3-carousel'
import slider from '@/components/SliderComponents/sliderV.vue'


import 'vue3-carousel/dist/carousel.css'

export default defineComponent({
  name: 'SliderPhoto',
  components: {
    // Carousel,
    // Slide,
    'v-slider': slider,
  },
  data: () => ({
    currentSlide: 0,
    options: {
                  items: 2,
                  margin: 10,
                  nav: true,
                  dots: true,
                  loop: true,
                  timing: 'cubic-bezier(0, 0.72, 0.64, 1.06)',
                  offset: 1,
                  prevNav: 'Назад',
                  nextNav: 'Далі',
                  sibling: true,
                  responsive : {
                      0: {
                          items: 1
                      },
                      700: {
                          items: 2
                      },
                      1024: {
                          items: 3
                      }
                  }
              }
    
  }),
  
  props: {
    slides: []
  },
  methods: {
    slideTo(val) {
      this.currentSlide = val
    },
    getImgUrl(pic) {
      return require('@/assets/' + pic);
    },
    prevSlide() {
      if (this.currentIndex > 0) {
        this.currentIndex--;
      }
    },
    nextSlide() {
      if (this.currentIndex < this.items.length - 1) {
        this.currentIndex++;
      }
    },
  },
})
</script>

<style scoped>

.slider {
  width: 650px;
}

.carousel__item {
  height: 400px;
  width: 250px;
  border-radius: 2px;
  color: var(--vc-clr-white);
  font-size: 20px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}
img {
  border-radius: 2px;
}
.carousel__item__bottom {
  height: 200px;
  width: 140px;
}

.carousel__slide {
  padding: 10px;
}

.carousel__prev,
.carousel__next {
  box-sizing: content-box;
  border: 5px solid white;
}

#galery {
  height: 100%;
}

.carousel__item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}


@media (max-width:430px) {
  .slider {
  width: 350px;
}

.carousel__item {
  height: 300px;
  width: 220px;
  border-radius: 2px;
  color: var(--vc-clr-white);
  font-size: 20px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}
img {
  border-radius: 2px;
}
.carousel__item__bottom {
  height: 150px;
}

.carousel__slide {
  padding: 10px;
}

}

.item {
  height: 350px;
}
</style>
