<template>
    <prototipePortfolio :slides="completedWorks"></prototipePortfolio>
</template>
  
<script>
  import prototipePortfolio from '@/components/completedWorks/prototipeBlockPortfolio.vue'
  
  export default {
    name: "CompletedBlock",
    components: {
        prototipePortfolio
    },
    data() {
      return {
        completedWorks: [
  
        {
          "id": 1.4,
          "photos": [
            {"id":1, "srcImg": "sliders/dubeshnya/01.webp"},
            {"id":2, "srcImg": "sliders/dubeshnya/02.webp"},
            {"id":3, "srcImg": "sliders/dubeshnya/03.webp"},
            {"id":4, "srcImg": "sliders/dubeshnya/04.webp"},
            {"id":5, "srcImg": "sliders/dubeshnya/05.webp"},
            {"id":6, "srcImg": "sliders/dubeshnya/06.webp"},
            {"id":7, "srcImg": "sliders/dubeshnya/07.webp"},
            {"id":8, "srcImg": "sliders/dubeshnya/08.webp"},
            {"id":9, "srcImg": "sliders/dubeshnya/09.webp"},
            ],
          
          "name_portfolioItem" : "Ремонт приватного будинку в Дубечні",
          "location_p": "с. Дубечня",
          "footage_p": "140 кв. м.",
          "cost_p": "66.5 тис. $",
          "period_p": "12 міс.",
          "completedWorks_p": "Ремонт новобудови приватного будинку. Дві спальні, два санвузла, вітальня, кухня, передпокій, сервісні приміщення. Двері прихованого монтажу, деревʼяна підлога та сходи, сучасна італійська плитка під замовлення, фасадні роботи - використання клінкерної плитки та штукатурки. Дах - натуральна черепиця.",
          "portfolioLink": "/dubechnya"
        },
  
  
        {
          "id": 1.1,
          "photos": [
            {"id":1, "srcImg": "sliders/zapovitniy/01.webp"},
            {"id":2, "srcImg": "sliders/zapovitniy/02.webp"},
            {"id":3, "srcImg": "sliders/zapovitniy/03.webp"},
            {"id":4, "srcImg": "sliders/zapovitniy/04.webp"},
            ],
          
          "name_portfolioItem" : " Ремонт на пер. Заповітний, приватний будинок",
          "location_p": "Київ, Соломенка",
          "footage_p": "215 кв. м.",
          "cost_p": "146 тис. грн.",
          "period_p": "3 тижні",
          "completedWorks_p": "Виконання фасадних робіт",
          "portfolioLink": "/zapovitniy"
        },
  
            {
          "id": 1.2,
          "photos": [
            {"id":1, "srcImg": "sliders/jkYaskraviy/01.webp"},
            {"id":2, "srcImg": "sliders/jkYaskraviy/02.webp"},
            {"id":3, "srcImg": "sliders/jkYaskraviy/03.webp"},
            {"id":4, "srcImg": "sliders/jkYaskraviy/04.webp"},
            {"id":5, "srcImg": "sliders/jkYaskraviy/05.webp"},
            {"id":6, "srcImg": "sliders/jkYaskraviy/06.webp"},
            {"id":7, "srcImg": "sliders/jkYaskraviy/07.webp"},
            {"id":8, "srcImg": "sliders/jkYaskraviy/08.webp"},
            {"id":9, "srcImg": "sliders/jkYaskraviy/09.webp"}
            ],
          
          "name_portfolioItem" : " Ремонт на Калнишевського, Новобудова",
          "location_p": "ЖК Яскравий",
          "footage_p": "27 кв. м.",
          "cost_p": "15 тис. $",
          "period_p": "3 міс.",
          "completedWorks_p": "Повне перепланування, нова електрика, сантехніка, штукатурка, тепла підлога. Ремонт квартири новобудова після будівництва.",
          "portfolioLink": "/yaskraviy"
        },
  
        {
          "id": 1.3,
          "photos": [
            {"id":1, "srcImg": "sliders/sinergiyaCity/01.webp"},
            {"id":2, "srcImg": "sliders/sinergiyaCity/02.webp"},
            {"id":3, "srcImg": "sliders/sinergiyaCity/03.webp"},
            {"id":4, "srcImg": "sliders/sinergiyaCity/04.webp"},
            {"id":5, "srcImg": "sliders/sinergiyaCity/05.webp"},
            {"id":6, "srcImg": "sliders/sinergiyaCity/06.webp"},
            {"id":7, "srcImg": "sliders/sinergiyaCity/07.webp"},
            {"id":8, "srcImg": "sliders/sinergiyaCity/08.webp"},
            {"id":9, "srcImg": "sliders/sinergiyaCity/09.webp"},
            {"id":10, "srcImg": "sliders/sinergiyaCity/10.webp"},
            {"id":11, "srcImg": "sliders/sinergiyaCity/11.webp"},
            ],
          
          "name_portfolioItem" : "Ремонт квартири в Ірпені, Новобудова",
          "location_p": "ЖК Синергія Сіті",
          "footage_p": "78 кв. м.",
          "cost_p": "12.5 тис. $",
          "period_p": "4 міс.",
          "completedWorks_p": "2 спальні, 2 санвузли, кухня-їдальня, сходи, два балкони. Штукартука, шпаклівка, плиточні роботи, фарбування, гіпсокартівні роботи.",
          "portfolioLink": "/sinergiyacity"
        },
  
        {
          "id": 1.4,
          "photos": [
            {"id":1, "srcImg": "sliders/varshavskyi/01.webp"},
            {"id":2, "srcImg": "sliders/varshavskyi/02.webp"},
            {"id":3, "srcImg": "sliders/varshavskyi/03.webp"},
            {"id":4, "srcImg": "sliders/varshavskyi/04.webp"},
            {"id":5, "srcImg": "sliders/varshavskyi/05.webp"},
            {"id":6, "srcImg": "sliders/varshavskyi/06.webp"},
            ],
          
          "name_portfolioItem" : "Ремонт квартири в ЖК Варшавський, Новобудова",
          "location_p": "ЖК Варшавський",
          "footage_p": "40.1 кв. м.",
          "cost_p": "870 тис. грн.",
          "period_p": "9 міс.",
          "completedWorks_p": "Ремонт в новобудові за адресою:  ЖК Варшавський було виконано: з 15.03.2022 - 12.12.2023. Вартість всіх робіт та чорнових матеріалів склала  870000. Площа квартири склала 40,1 м.кв.",
          "portfolioLink": "/varshavskiy"
        },
  
        {
          "id": 1.5,
          "photos": [
            {"id":1, "srcImg": "sliders/fainaTown/01.webp"},
            {"id":2, "srcImg": "sliders/fainaTown/02.webp"},
            {"id":3, "srcImg": "sliders/fainaTown/03.webp"},
            {"id":4, "srcImg": "sliders/fainaTown/04.webp"},
            {"id":5, "srcImg": "sliders/fainaTown/05.webp"},
            {"id":6, "srcImg": "sliders/fainaTown/06.webp"},
            {"id":7, "srcImg": "sliders/fainaTown/07.webp"},
            {"id":8, "srcImg": "sliders/fainaTown/08.webp"},
            ],
          
          "name_portfolioItem" : "Ремонт квартири в ЖК Файна Таун, Новобудова",
          "location_p": "ЖК Файна Таун",
          "footage_p": "63 кв. м.",
          "cost_p": "720 тис. грн.",
          "period_p": "5 міс.",
          "completedWorks_p": "Ремонт квартири 63 м2, 720000 грн, термін виконання 5 місяців, квартира за адресою ЖК Файна таун.",
          "portfolioLink": "/faynatown"
        },
  
  
  
  
  
  
  
        ],
        uniqueKey: 0 
      }
    },
    methods: {
      subscribeToComponentAgain() {
        // При повторной подписке обновляем уникальный ключ
        this.uniqueKey += 1;
      },
    }
  }
  </script>
  
  <style>
  
  </style>