<template>
  <section class="stagesBlock">

    <p class="titleStages">Ми про все подбали та зробили співпрацю з нами комфортною</p>
    <div class="stagesList">
      <div class="stagesList_item" v-for="stage in stages" :key="stage.id">
        <p class="number"> {{ stage.number }}</p>
        <h2 class="title">{{ stage.title }}</h2>
        <p class="text">{{ stage.text }}</p>
      </div>
      <img src="@/assets/final-result.webp" alt="">
    </div>

    <div class="formScreen">
      <div class="leftSide">
        <h1><span>Готові до обговорення вашого проекту?</span> Безкоштовно викличте нашого фахівця в зручний для вас час</h1>
        <ul>
          <li>Зробимо точні заміри</li>
          <li>Допоможем з визначенням напрямку дизайну приміщення</li>
          <li>Підкажемо з вибором матеріалів</li>
          <li>Проконсультуємо за видами робіт та їх етапів, терміну та вартості</li>
        </ul>
      </div>
      <div class="formMainScreen">
      <p>Заповніть форму, ми передзвонимо вам та узгодимо дату та час візита фахівця до вас</p>
      <!-- <MainForm class="form"></MainForm> -->
      <MainForm2 class="mf2"></MainForm2>
    </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'StagesBlock',
  data() {
    return {
      stages: [
        { 
          number: "01", 
          title: "Звʼяжіться з нами зручним для вас способом", 
          text: "Залишаєте заявку, передзвонюємо та дізнаємось ваші потреби, який ремонт вам потрібен" },
        {
          number: "02",
          title: "Приїздимо на обʼєкт",
          text: "Робимо заміри, прораховуємо терміни та вартість"
        },
        {
          number: "03",
          title: "Підписуємо договір",
          text: "Складаємо кошторис, терміни та умови оплати"
        },
        {
          number: "04",
          title: "Виконуємо роботи",
          text: "Щотижневі звіти в зручному для вас месенджері та можливість спостерігати за роботами онлайн"
        },
        {
          number: "05",
          title: "Отримання ключів та безкоштовний кліринг",
          text: "Отримайте безкоштовний кліринг при передачі ключів та насолоджуйтесь результатом"
        }

    ]
    };
  },
}
</script>

<style scoped>

.mf2{
  width: 100%;
}


  .formMainScreen {
    display: flex;
    flex-direction: column;
    gap: 30px;
    width: 300px;
    align-items: center;
    justify-content: center;
  }

  .formMainScreen p {
    width: 300px;
    text-align: center;
    color:#322929;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .form {
    flex-direction: column;
    max-width: 300px;
    align-items: center;
    justify-content: center;
    
  }

  .form button {
    width: 300px;
  }

  .formScreen {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    align-items: baseline;
    justify-content: center;
    margin-top: 30px;
  }

  .leftSide {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }

  .leftSide h1 {
    width: 600px;
    color:  #322929;
    font-size: 32px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
  
  }

  .leftSide h1 span {
    color:  #ED7C0E;
  }

  .leftSide ul {
    padding: 20px;
    background: #ECF1F8;
  }

  .leftSide ul li {
    color: #322929;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
      }

.titleStages {
  margin-top: 70px;
  width: 567px;
  color:  #322929;
  text-align: center;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.stagesBlock {
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: center;
  justify-content: center;
  padding: 0 100px;
  margin-bottom: 50px;
}

.stagesList {
  display: flex;
  width: 950px;
  flex-wrap: wrap;
  gap: 30px;
  align-items: center;
  justify-content: flex-start;
  position: relative;
}

.stagesList_item {
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: flex-start;
  justify-content: baseline;
  border-radius: 2px;
  border: 1px solid  #322929;
  height: 330px;
  padding: 50px 20px;
}

.stagesList_item .number {
  color:  #ED7C0E;
  font-size: 40px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  text-transform: uppercase;
}

.stagesList_item .title {
  width: 226px;
  color:  #322929;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.stagesList_item .text {
  width: 225px;
  color:  #322929;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.stagesList img {
  width: 350px;
  height: 350px;
  border-radius: 2px;
  border: 1px solid  #ED7C0E;
  padding: 5px;
  position: absolute;
  bottom: -40px;
  right: -10px;
}

@media (max-width: 430px) {

  .stagesBlock {
    padding: 0 0;
    margin: 30px;
  }
  .stagesList {
    justify-content: center;
    flex-direction: column;
    max-width: 390px;
  }

  .stagesList img {
    display: none;
  }

  .titleStages {
    width: 350px;
  }

  .formScreen, .leftSide {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .leftSide h1 {
    width: 350px;
    font-size: 20px;
    text-align: center;
  }
}

@media (min-width: 431px) and (max-width: 1024px) {

.stagesBlock {
  padding: 0 0;
  margin-top: 30px;
}
.stagesList {
  justify-content: center;
}

.stagesList_item {
  padding: 50px 10px;
}

.stagesList img {
  display: none;
}

.titleStages {
  width: 350px;
}

.formScreen, .leftSide {
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.leftSide h1 {
  width: 350px;
  font-size: 20px;
  text-align: center;
}
}
</style>