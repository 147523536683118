<template>
  <section class="photoReviews">
    <div class="olegText">
      <div class="leftSide">
        <img src="@/assets/oleg/olegSquare2.png" alt="">
      </div>
      <div class="rightSide">
        <i><span>“Я пишаюсь</span> кожним ремонтом або будівництвом та дорожу своєю та репутацією команди”</i>
        <p>Олег роскін, власник “Roskin”</p>
      </div>
    </div>
  </section>
  
</template>

<script>



export default {
  name: "PhotoReviews",
  
};
</script>

<style scoped>

.photoReviews {
  margin: 50px 100px;
}
  

.olegText {
  display: flex;
  gap: 30px;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-bottom: 50px;
}


.rightSide {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.rightSide i {
  width: 737px;
  color: #322929;
  font-size: 20px;
  font-weight: 700;
}

.rightSide i span {
  color: #ED7C0E;
}


.rightSide p {
    color:  #322929;
    font-size: 15px;
    font-weight: 300;
    text-transform: uppercase;
}

.leftSide img {
  width: 200px;
  height: 200px;
  border-radius: 50%;
}


@media (max-width: 430px) {
  .photoReviews {
  margin: 30px 0;
  }
  .rightSide i, .rightSide p {
    width: 350px;
    text-align: center;
  }
  .attach {
      width: 100%;
      max-width: 1000px;
      min-width: 330px;
      padding: 50px 10px;
      margin: 0 auto;
      position: relative;
  }
}

@media (min-width: 431px) and (max-width: 1024px) {
  .photoReviews {
  margin: 30px 20px;
  }
  .rightSide i, .rightSide p {
    width: 500px;
    text-align: center;
  }

  .item {
    width: 120px;
    height: 600px;
  }
}

</style>
