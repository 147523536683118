<template>
  <section class="logos" id="logos">
    <div class="eUpdate">
      <p class="p_eUpdate"><b>Учасник програма допомоги від держави</b>
      Для власників житла — пошкодженого або зруйнованого через бойові дії</p>
      <img src="@/assets/icons/e_update.jpg" style="width: 300px;" alt="Програма Є-Відновлення">
    </div>
    <div class="logos__container container">
        <h2 class="logos__title">Наші клієнти:</h2>
        <div class="logos__items">
          <div class="logoClient" v-for="logo in clientsLogo" :key="logo.id">
            <img :src="getImgUrl(logo.srcImg)" alt="logo-client">
          </div>
        </div>
    </div>
</section>
</template>

<script>
import 'slick-carousel/slick/slick.css'; // Импорт стилей Slick
import 'slick-carousel/slick/slick-theme.css'; // Импорт темы Slick
import $ from 'jquery'; // jQuery требуется для работы Slick
import 'slick-carousel'; // Импорт Slick
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'OurClientsList',
  data() {
    return {
      clientsLogo: [
        { srcImg: 'ourClients/guliver-logo.webp' },
        { srcImg: 'ourClients/comfort-town.webp' },
        { srcImg: 'ourClients/novopechersk.webp' },
        { srcImg: 'ourClients/faina-town.webp' },
        { srcImg: 'ourClients/great-jk.webp' },
        { srcImg: 'ourClients/nova-angliya.webp' },
        { srcImg: 'ourClients/dgerela_logo.webp' },
        { srcImg: 'ourClients/metropolis.webp' }
    ]
    };
  },
  methods: {
    getImgUrl(pic) {
      return require('@/assets/' + pic);
    },
  },
  mounted() {
      $('.logos__items').not('.slick-initialized').slick({
      variableWidth: true,
      arrows: false,
      autoplay: true,
      autoplaySpeed: 0,
      speed: 8000,
      cssEase: 'linear',
      pauseOnHover: true,
      swipeToSlide: true,
    });

  }
})
</script>

<style scoped>
.logos {
        text-align: center;
        margin-top: 30px;
        
    }

    .logos__container {
        max-width: 100%;
        padding: 0;
    }

    .logos__title {
        margin-bottom: 30px;
        font-size: 40px;
        color: #322929;
        font-weight: 900;
    }

    .logos__items img {
        width: 172px !important;
        height: 90px;
        margin-right: 15px;
        background: #F7F5FB 50%/70% no-repeat;
        border-radius: 5.44433px;
    }
    @media only screen and (max-width: 1260px) {
        .logos {
            padding-top: 0;
        }
        .logos__title {
            font-size: 14px;
        }
    }

    .p_eUpdate {
      max-width: 300px;
      text-align: start;
      font-size: 15px;
    }

    .eUpdate {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      gap: 20px;
      margin: 30px 0;
    }

    @media (max-width: 430px) {
      .p_eUpdate {
      text-align: center;
    }
    }

</style>
