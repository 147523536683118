<template>
  <section class="calculate">
    <main class="calc-wrapper">
			<div class="container">
				<form id="form" style="height: inherit;">
					<div class="heading">
						<h1 class="heading-title">Калькулятор вартості ремонту</h1>
						<p class="heading-desc">Базова ціна: 1 м² = 7400 грн.</p>
					</div>

					
          <div 
          v-if="activeTab === 0" 
          :key="0"
          :class="{ 'active': activeTab === index }"
        >
            <!-- Площадь -->
            <div class="calc-section">
						<label class="checkbox-wrapper title-bold section-title .section-title--vertical-center">
							<span class="title__inline">Площа квартири/дому:</span>
							<input type="number" min="0" max="200" id="square-input" class="title__inline input-short" v-model="inputValue"/>
							<span class="title__inline">м²</span>
						</label>
						<input type="range" id="square-range" class="range-input" min="0" max="200" step="1" v-model="inputValue"/>

						<h4 class="checkbox-wrapper title-bold section-title">Виберіть тип Вашого обʼєкту</h4>

						<div class="choice">
							<label class="radio-wrapper" data-name="mobile">
							<img src="@/assets/calculator/step1/novobud.webp" width="100%" height="200px" alt="">
							
							<div class="v1">
								<input type="radio" class="radio" name="option" value="7400" v-model="objectTypeValue"/>
								<div class="title-lite">Новобудова</div>
							</div>
						</label>

						<label class="radio-wrapper" data-name="mobile">
							<img src="@/assets/calculator/step1/vtorinne.webp" width="100%" height="200px" alt="">
							<div class="v1">
								<input type="radio" class="radio" name="option" value="8200" v-model="objectTypeValue" checked/>
								<div class="title-lite">
									Вторинне житло
								</div>
							</div>
						</label>

						<label class="radio-wrapper" data-name="mobile">
							<img src="@/assets/calculator/step1/privateHouse.webp" width="100%" height="200px" alt="">
							<div class="v1">
								<input type="radio" class="radio" name="option" value="9250" v-model="objectTypeValue"/>
								<div class="title-lite">
									Приватний будинок
								</div>
							</div>
						</label>

						<label class="radio-wrapper" data-name="mobile">
							<img src="@/assets/calculator/step1/comerce.webp" width="100%" height="200px" alt="">
							<div class="v1">
								<input type="radio" class="radio" name="option" value="8500" v-model="objectTypeValue"/>
								<div class="title-lite">
									Комерційна нерухомість
								</div>
							</div>
						
						</label>
						</div>
					</div>
					<!-- // Площадь -->
          <!-- // Мобильная адаптивность -->
          </div>

          <div v-if="activeTab === 1"
          :key="1"
          :class="{ 'active': activeTab === index }">
            <!-- Мобильная адаптивность -->
						<div class="calc-section">
						<h4 class="checkbox-wrapper title-bold section-title">Який тип ремонту вас цікавить</h4>

						<div class="choice">
							<label class="radio-wrapper" data-name="mobile">
							<img src="@/assets/calculator/step2/kosmetic.webp" width="100%" height="200px" alt="">
							<div class="v1">
								<input type="radio" class="radio" name="option" value="1" v-model="repairTypeValue"/>
								<div class="title-lite">Косметичний</div>
							</div>
						</label>

						<label class="radio-wrapper" data-name="mobile">
							<img src="@/assets/calculator/step2/evro.webp" width="100%" height="200px" alt="">
							<div class="v1">
								<input type="radio" class="radio" name="option" value="1.1" checked v-model="repairTypeValue" />
								<div class="title-lite">
									Евро
								</div>
							</div>
						</label>

						<label class="radio-wrapper" data-name="mobile">
							<img src="@/assets/calculator/step2/pidcluch.webp" width="100%" height="200px" alt="">
							<div class="v1">
								<input type="radio" class="radio" name="option" value="1.3" v-model="repairTypeValue"/>
								<div class="title-lite">
									Під ключ
								</div>
							</div>
						</label>

						<label class="radio-wrapper" data-name="mobile">
							<img src="@/assets/calculator/step2/elitnii.webp" width="100%" height="200px" alt="">
							<div class="v1">
								<input type="radio" class="radio" name="option" value="1.5" v-model="repairTypeValue"/>
								<div class="title-lite">
									Елітний
								</div>
							</div>
						</label>
						</div>
					</div>
          </div>

          <div v-if="activeTab === 2"
          :key="2"
          :class="{ 'active': activeTab === index }">
            <!-- Дополнительные опции -->
					<div class="calc-section">
						<label class="checkbox-wrapper title-bold section-title">Чи є у Вас дизайн-проект </label>
						
						<div class="csInside">
							<div class="leftS">
							<label class="radio-wrapper2">
							<input type="radio" class="radio" name="option" value="1" v-model="hasDesignProjectValue"/>
							<div class="title-lite">
								Так, є
							</div>
							</label>
					
							<label class="radio-wrapper2">
								<input type="radio" class="radio" name="option" checked value="1.1" v-model="hasDesignProjectValue"/>
								<div class="title-lite">
									Ні, немає
								</div>
							</label>
						</div>
						
						<div class="rightS">
							<img src="@/assets/calculator/step3/designproject.webp" width="250px" alt="">
						</div>
						</div>
					</div>
					<!-- // Дополнительные опции -->
          </div>

          <div v-if="activeTab === 3"
          :key="3"
          :class="{ 'active': activeTab === index }">
            <!-- Стоимость ремонта -->
					<div class="calc-price">
						<div class="calc-price-title">Вартість ремотну (від):</div>
						<div class="calc-price-value">
							<span id="total-price"><span>від </span>{{ calculateTotalPrice }}</span>
							гривень
						</div>
						<MainForm2 class="form" :SqMetersValue="inputValue"></MainForm2>
					</div>
					<!-- // Стоимость ремонта -->
          </div>
				</form>
			</div>
		</main>
    <div class="btns">
      <button class="btnBack" @click.prevent="backTab">Назад</button>
      <button class="btnNext" @click.prevent="changeTab">Далі</button>
    </div>
  </section>
</template>

<script>

export default {
  name: 'CalculatorApp',
  data() {
    return {
      activeTab: 0,
      inputValue: 50,
			objectTypeValue: 8200,
			repairTypeValue: 1.1,
			hasDesignProjectValue: 1.1
    }
  },
	computed: {
  calculateTotalPrice() {
    // Your formula for calculating the total price based on the selected values
    const squareMeters = this.inputValue || 0;
		console.log(this.inputValue)
    const objectTypePrice = parseFloat(this.objectTypeValue) || 0;
		console.log(this.objectTypeValue)
    const repairTypeMultiplier = parseFloat(this.repairTypeValue) || 1;
		console.log(this.repairTypeValue)
    const hasDesignProjectMultiplier = parseFloat(this.hasDesignProjectValue) || 1;
		console.log(this.hasDesignProjectValue)

    const totalPrice = squareMeters * objectTypePrice * repairTypeMultiplier * hasDesignProjectMultiplier;
		console.log(totalPrice)

    return totalPrice.toFixed(0); // Adjust as needed
  }
},
  methods: {
		
    changeTab(event) {
			event.preventDefault();
      if (this.activeTab <= 2) {
        this.activeTab +=1;
      }
    },
    backTab(event) {
			event.preventDefault();
      if (this.activeTab >= 1) {
        this.activeTab -=1;
      }
    }
  }
}
</script>

<style scoped>


.form {
	margin-top: 100px;
}

.hide {

  display: none;
}
  
h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0;
}

.calculate {
	margin-top: 50px;
}

/* ------------------- Calc ------------------- */
.calc-wrapper {
	max-width: 900px;
	margin: 0 auto;
}

.heading {
	margin-bottom: 50px;
	text-align: center;
}

.heading-title {
	margin: 0;
	margin-bottom: 15px;
	font-size: 40px;
	font-weight: 700;
	line-height: 1;
	letter-spacing: 0.48px;
	text-align: center;
}

.heading-desc {
}

.calc-section {
	padding: 15px;
	text-align: center;
}

.calc-section:nth-child(odd) {
	padding: 40px 50px;
	background-color: #fff;
}

.calc-title-light {
	font-size: 24px;
	font-weight: 300;
	margin-bottom: 15px;
}

.title__inline {
	display: inline-block;
	vertical-align: middle;
}

.title__inline + .title__inline {
	margin-left: 10px;
}

.input-short {
	width: 20px;
}

.choice {
	display: flex;
	justify-content: center;
	gap: 15px;
	flex-wrap: wrap;
}

.radio-wrapper {
	display: flex;
	flex-direction: column;
	/* align-items: center; */
	max-width: 250px;
	gap: 10px;
}

.v1 {
	display: flex;
	gap: 4px;
	align-items:flex-start;
	max-width: 150px;
}


.radio-wrapper2 {
	display: flex;
	gap: 20px;
}

.csInside {
	display: flex;
	justify-content: center;
	gap: 30px;
	
}

.radio-wrapper:not(:last-child) {
	margin-bottom: 30px;
}

/* .radio-holder {
	margin-right: 15px;
}

.radio-holder input{
	margin-top: 6px;
} */

/* .radio {
	margin-right: 15px;
} */

.input-wrapper {
	display: flex;
	margin-bottom: 40px;

}

.input-wrapper:last-child {
	margin-bottom: 0;
}

.input-short {
	width: 62px;
	height: 36px;
	border-radius: 4px;
	border: 1px solid #9a9a9a;
	font-size: 24px;
	font-weight: 700;
	color: #ED7C0E;
	text-align: center;
}

.input-wrapper .input-short:first-child {
	margin-right: 20px;
}

.input-wrapper .input-short:last-child {
	margin-left: 20px;
}

/* ------------ TYPO ------------ */

.section-title {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 20px;
	margin-top: 20px;
}

.section-title--vertical-center {
	align-items: center;
}

.title-bold {
	font-size: 24px;
	font-weight: 700;
}

.title-lite {
	font-size: 16px;
	font-weight: 300;
	text-align: left;
}

.note {
	display: block;
	font-weight: 300;
	font-style: italic;
	padding-top: 10px;
	font-size: 16px;
	color: #969696;
}

/* ------------ FORMS ------------ */

.checkbox-wrapper {
	display: flex;
	margin-bottom: 20px;
  color: #ED7C0E;
}

.checkbox-wrapper .checkbox {
	margin-right: 15px;
	text-align: center;
}


/* ------------ PRICE ------------ */
.calc-price {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 30px 50px;
	/* border: 10px solid #7ac5ea; */
	background-color: #ED7C0E;
	background-color: white;
}

.form {
	align-items: center;
	justify-content: center;
}

.calc-price-title {
	font-size: 32px;
	font-weight: 700;
	margin-bottom: 10px;
}

.calc-price-value {
	font-size: 26px;
	font-weight: 300;
}

.hidden {
	display: none;
}

.range-input {
	width: 100%;
}


.rooms-wrapper {
	display: flex;
	flex-direction: start;
	align-items: center;
}

.rooms-label {
}
.rooms-radio-real {
	opacity: 0;
	appearance: none;
	height: 0;
	width: 0;
	margin: 0;
}

.rooms-radio-fake {
	display: inline-block;
	width: 50px;
	height: 50px;
	background-color: #e2e2e2;
	text-align: center;
	line-height: 50px;
	font-size: 22px;
	/* font-weight: bold; */
	cursor: pointer;
}

.rooms-radio-real:checked + .rooms-radio-fake {
	background-color: #ED7C0E;
  color: white;
}

.btns {
  display: flex;
  gap: 30px;
  align-items: center;
  justify-content: center;
}

.btnBack {
  color: white;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 60px;
    padding: 0 50px;
    border-radius: 2px;
    background: #6b6b6b;
    border: none;
    cursor: pointer;
    text-transform: uppercase;
}

.btnBack:hover {
  background: #322929;
}


.btnNext {
  color: #FFF;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 60px;
    padding: 0 50px;
    border-radius: 2px;
    background: #ED7C0E;
    border: none;
    cursor: pointer;
    text-transform: uppercase;
}

.calculate {
  margin-bottom: 100px;
}
input[type="range"] {

}

.radio-wrapper img, .rightS img{
	width: 150px;
	height: 150px;
}


@media (max-width: 430px) {
	.choice {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}
	
	.csInside {
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	.section-title {
		text-align: center;
		font-size: 20px;
	}
}


</style>