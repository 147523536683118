<template>
  <section>
    <h1>Приклади виконаних робіт</h1>
    <CompletedBlock></CompletedBlock>
  </section>
  
</template>

<script>
import CompletedBlock from '@/components/completedWorks/completedBlock.vue'

export default {
  name: 'PortfolioView',
  components: {
    CompletedBlock
  }
}
</script>

<style scoped>
  h1 {
    font-size: 40px;
    font-weight: 900;
    color: #322929;
    text-align: center;
    margin-top: 50px;
  }



</style>